<template>
  <div>
    <!-- Modal -->
    <div id="demo-modal" class="modal">
      <div class="modal__content">
        <div class="d-flex justify-content-between">
          <h4 class="">Add Beneficiary</h4>
          <i @click="closeModal" class="fa fas fa-close"></i>
        </div>
        <hr />

        <!-- <div class="form-group">
          <h6 for="purchase for">Select Beneficiary Type</h6>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="beneficiary"
              type="radio"
              value="1"
              id="group3"
            />
            <label class="form-check-label" for="group3">Oonpay Contact</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              v-model="beneficiary"
              type="radio"
              value="2"
              id="group4"
            />
            <label class="form-check-label" for="group4">Other Contact</label>
          </div>
        </div> -->

        <div class="py-3" v-if="beneficiary == 2">
          <h6 for="purchase for">Enter Beneficiary Details</h6>
          <div class="form-group">
            <input type="text" placeholder="enter full name" class="form-control" v-model="name" />
          </div>
          <div class="row">
            <div class="col-md-3">
              <select v-model="callCode" class="form-control" id="">
                <option :value="item" v-for="item in all_countries" :key="item.id">{{ item.fullName }}</option>
              </select>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                <input v-model="phonenumber" type="text" class="form-control" placeholder="000-000-0000" />
              </div>
            </div>
          </div>
        </div>

        <div class="py-3">
          <!-- <h6 for="purchase for">Enter Beneficiary Details</h6> -->
          <div class="row">
            <div class="col-md-3">
              <label for="">select country</label>
              <select v-model="callCode" @change="handleChange" class="form-control" id="">

                <option :value="item" v-for="item in all_countries" :key="item.id">{{ item.fullName }}</option>
              </select>
            </div>
            <div class="col-md-9">
              <label for="">Enter phone number</label>

              <div class="form-group">
                <input v-model="phonenumber" type="text" class="form-control" placeholder="000-000-0000" />
              </div>
            </div>

            <div class="col-md-12" v-if="showOnpayUserInput">
              <div class="form-group text-grey-500">
                <input v-model="username" disabled type="text" class="form-control" placeholder="enter fullname" />
              </div>
              <p>
                This Beneficiary is on Oonpay. They will receive a notification
                to log unto their dashboard and view details of this package
              </p>
            </div>
            <div class="col-md-12" v-if="showNonOnpayTextBox">
              <div class="form-group text-grey-500">
                <input v-model="username" type="text" class="form-control" placeholder="Enter fullname" />
              </div>
              <p>
                This Beneficiary is not on Oonpay. They will receive a text
                message of a voucher Pin that will be used to redeem this
                package from a Merchant. This Voucher pin will also be mailed to
                you.
              </p>
            </div>
          </div>
        </div>
        <p v-if="error" class="text-danger">{{ error }}</p>
        <button @click.prevent="findBeneficiary" class="btn btn-primary btn-block" v-if="showSearchBeneficiary">
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else>SEARCH BENEFICIARY</span>
        </button>

        <button @click.prevent="addBeneficiary" class="btn btn-primary btn-block" v-if="showAddBeneficiary">
          ADD BENEFICIARY
        </button>
      </div>
    </div>

    <div class="form-group">
      <h6 for="purchase for">Purchase Pack For</h6>
      <div class="form-check form-check-inline">
        <input class="form-check-input" v-model="purchase_for" type="radio" value="3" />
        <label class="form-check-label" for="group1">My Self</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" @click="showModal" type="radio" v-model="purchase_for" value="others"
          id="group2" />
        <label class="form-check-label" for="group2">Others</label>
      </div>
    </div>

    <div v-if="showBenficiary">
      <div v-if="Object.keys(beneficiaryDetail).length !== 0"
        class=" mb-4 d-flex justify-content-between p-2 border-2 border-sky-500">
        <div>
          Beneficiary: &nbsp;
          <b>{{ beneficiaryDetail.name }}. &nbsp; &nbsp;
            {{ beneficiaryDetail.phone }}</b>
        </div>
        <div class="pointer border border-sky-500">
          <i @click="showModal" class="fa fas fa-pen"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["all_countries"],
  data() {
    return {
      beneficiary: "",
      callCode: "",
      phonenumber: "",
      showBenficiary:true,
      beneficiaryDetail:JSON.parse(localStorage.getItem("beneficiaryDetail")) || {},
      non_oonpay_user: "",
      usernum: "",
      name: "",
      error: "",
      purchase_for: localStorage.getItem("purchase_for") || 3,
      username: "",
      showSearchBeneficiary: true,
      showAddBeneficiary: false,
      showTextBox: false,
      showNonOnpayTextBox: false,
      showOnpayUserInput: false,
      loading: false
    };
  },
  watch: {
    purchase_for: function(val) {
      console.log(val)
      localStorage.setItem("purchase_for",val)
      if (val == "others") {
       // this.beneficiaryDetail = {};
        //this.showOnpayUserInput = false;
        localStorage.setItem("BeneficiaryTypeId", 3);
        this.showBenficiary = false
        //this.showNonOnpayTextBox = false;
        //this.phonenumber = "";
      }else{
        this.showBenficiary = false
      }
     
    },
    // beneficiary: function(val) {
    //   localStorage.setItem("BeneficiaryTypeId", val);
    // },
    callCode: function(val) {
      localStorage.setItem("BeneficiaryCountryId", val.id);
    },
    phonenumber(newValue, oldValue) {
      if (newValue) {
        //this.username = "";
        this.showSearchBeneficiary = true;
        this.showAddBeneficiary = false;
        this.showOnpayUserInput = false;
        //this.showNonOnpayTextBox = false;
        this.error = "";
      }
    }
  },

  mounted(){
    localStorage.setItem("BeneficiaryTypeId", 3)
  },

  computed: {
    isUsernameValid() {
      return this.username.trim() !== "" && typeof this.username === "string";
    }
  },

  methods: {
    showModal() {
      const modal = document.getElementsByClassName("modal");
      modal[0].style.visibility = "visible";
      modal[0].style.opacity = 1;

      console.log(this.beneficiaryDetail)
      this.username = this.beneficiaryDetail.name
      this.phonenumber = this.beneficiaryDetail.phone

      if (this.beneficiaryDetail  && this.showNonOnpayTextBox == false){
        this.showNonOnpayTextBox == true,
          this.showOnpayUserInput == false
        this.showSearchBeneficiary = false;
        this.showAddBeneficiary = true;
        this.username = this.beneficiaryDetail.name
      }

      if (Object.keys(this.beneficiaryDetail).length !== 0 && this.showOnpayUserInput == false) {
        this.showNonOnpayTextBox == false,
          this.showOnpayUserInput == true
        this.showSearchBeneficiary = false;
        this.showAddBeneficiary = true;
      }

     
    },
    closeModal() {
      const modal = document.getElementsByClassName("modal");
      modal[0].style.visibility = "hidden";
      modal[0].style.opacity = 0;
      this.showBenficiary = true

      if (!this.beneficiaryDetail.phone) {
        this.purchase_for = 3;
        this.callCode = ""
      }
    },
    findBeneficiary() {
      if (!this.phonenumber) {
        return (this.error = "Phone number can not be empty");
      }

      let countryCodeRegex = /^(\+233|\+234)/;
      if (this.phonenumber.startsWith("+233") || this.phonenumber.startsWith("+234")){
        var user_phone = this.phonenumber.replace(countryCodeRegex, '0');
      }else{
          user_phone = this.phonenumber
      }

      
      if (user_phone.charAt(0) == 0 || user_phone.charAt(0) == 1) {
        var userPhone = user_phone.substring(1);
      } else {
        userPhone = user_phone;
      }
      this.usernum = `${this.callCode.callCode}${userPhone}`;

      this.loading = true
      this.$store
        .dispatch("default/findBenefactor", this.usernum)
        .then((res) => {
          if (res.data == "") {
            this.showNonOnpayTextBox = true;
            this.showSearchBeneficiary = false;
            this.showAddBeneficiary = true;
            localStorage.setItem("BeneficiaryTypeId", 2);
            this.loading = false
          } else {
            this.username = res.data;
            localStorage.setItem("BeneficiaryTypeId", 1);
            this.showOnpayUserInput = true;
            this.showTextBox = true;
            (this.showSearchBeneficiary = false),
              (this.showAddBeneficiary = true);
            this.loading = false
          }
        }).catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });

      // if (this.beneficiary == 1) {
      //   this.$store
      //     .dispatch("foodhub/BeneficiaryDetails", usernum)
      //     .then((res) => {
      //       if (res.status == 200) {
      //         this.beneficiaryDetail = {
      //           phone: usernum,
      //           name: res.data
      //         };
      //         const modal = document.getElementsByClassName("modal");
      //         localStorage.setItem("BeneficiaryFullName", res.data);
      //         localStorage.setItem("BeneficiaryPhoneNumber", usernum);
      //         modal[0].style.visibility = "hidden";
      //         modal[0].style.opacity = 0;
      //         this.phonenumber = "";
      //         this.error = "";
      //       } else {
      //         this.error = `${usernum} is not on Oonpay. Please check and try again`;
      //       }
      //     });
      // } else {
      //   this.beneficiaryDetail = {
      //     phone: usernum,
      //     name: this.name
      //   };
      //   localStorage.setItem("BeneficiaryFullName", this.name);
      //   localStorage.setItem("BeneficiaryPhoneNumber", usernum);
      //   localStorage.setItem("BeneficiaryTypeId", this.beneficiary);
      //   const modal = document.getElementsByClassName("modal");
      //   modal[0].style.visibility = "hidden";
      //   modal[0].style.opacity = 0;
      // }
    },

    addBeneficiary() {
      if (!this.isUsernameValid) {
        return (this.error =
          "Beneficiary name can not be empty or name entered is invalid");
      }
      this.beneficiaryDetail = {
        phone: this.usernum,
        name: this.username
      };
      localStorage.setItem("beneficiaryDetail",JSON.stringify(this.beneficiaryDetail))
      this.showBenficiary = true
      const modal = document.getElementsByClassName("modal");
      localStorage.setItem("BeneficiaryFullName", this.username);
      localStorage.setItem("BeneficiaryPhoneNumber", this.usernum);
      modal[0].style.visibility = "hidden";
      modal[0].style.opacity = 0;
      this.error = "";
      //.showOnpayUserInput = false;
      // this.showNonOnpayTextBox = false;
      this.showAddBeneficiary = false;
      this.showSearchBeneficiary = true;
    },

    handleChange(){
      this.username = "";
        this.showSearchBeneficiary = true;
        this.showAddBeneficiary = false;
        this.showOnpayUserInput = false;
        this.showNonOnpayTextBox = false;
        this.error = "";
        
    }
  }
};
</script>

<style>
.oonpay-bg {
  background: #0c4369;
}

.modal {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -200px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(233, 225, 225, 0.836);
  transition: all 0.4s;
}

.modal:target {
  visibility: visible;
  opacity: 1;
}

.modal__content {
  border-radius: 4px;
  position: relative;
  width: 500px;
  max-width: 90%;
  background: #fff;
  padding: 1em 2em;
  /* top: -300px; */
}

.modal__footer {
  text-align: right;
}
a {
  color: #585858;
}

i {
  color: #d02d2c;
}
.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #585858;
  text-decoration: none;
}

input:disabled {
  background-color: rgba(
    233,
    225,
    225,
    0.836
  ); /* Change to your preferred grey color */
  color: #666666; /* Text color */
  /* Additional styling for disabled state */
}
</style>
