<template>
  <div id="demo-modal" class="modal">
    <div class="modal__content">
      <div class="flex justify-content-end">
        <button class="btn btn-sm btn-primary text-white" @click="goBack">
          close
        </button>
      </div>
      <div v-if="register">
        <!-- <h4 class="text-center">Register Now</h4> -->

        <div class="row py-4 d-flex justify-center">
          <div class="col-md-5">
            <img :src="profileIimage" alt="" />
            <div class="upload-btn-wrapper mt-2">
              <button class="btn">
                {{
                  profileIimage == null ? "upload profile" : "change profile"
                }}
              </button>
              <input
                @change="handleImage"
                type="file"
                name="myfile"
                id="myfile"
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="username">First Name</label>
              <input
                v-model="registerData.firstname"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="username">Last Name</label>
              <input
                type="text"
                v-model="registerData.lastname"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email Address</label>
          <input
            type="email"
            v-model="registerData.email"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="username">Phone</label>
          <div class="row">
            <div class="col-md-4">
              <select name="" v-model="countrycode" class="form-control" id="">
                <option
                  v-for="country in all_countries"
                  :key="country.id"
                  :value="country.callCode"
                  >{{ country.fullName }}</option
                >
              </select>
            </div>
            <div class="col-md-8">
              <input type="text" v-model="phonenumber" class="form-control" />
            </div>
            <div class="col-md-12">
              <a
                @click.prevent="sendVerificationCode"
                class="text-success font-bold cursor-pointer"
                >{{ sentcodeText }}</a
              >
            </div>
          </div>
        </div>

        <div v-if="showVerifyInput" class="form-group">
          <label for="verification code">Verification Code</label>
          <input type="text" v-model="verification_code" class="form-control" />
          <a
            @click.prevent="verifyCode"
            class="text-success font-bold cursor-pointer"
            >Verify Number</a
          >
        </div>

        <p class="text-success" v-if="showVerifySuccessMsg">
          Phone Number Verified successfully. Enter password to continue
        </p>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="username">Password</label>
              <input
                type="password"
                v-model="registerData.password"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="username">Confirm Password</label>
              <input
                type="password"
                v-model="registerData.confirm_password"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <p class="text-danger" v-if="showPasswordCheck">
        Passwords Do not match
      </p>
      <div v-if="showAPIError">
        <p class="text-danger" v-for="error in errors" :key="error.index">
          {{ error.error }}
        </p>
      </div>

      <div v-if="login">
        <h4 class="text-center">Login Now</h4>
        <div class="form-group">
          <label for="username">Email</label>
          <input
            v-model="loginWithPass.email"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="username">Password</label>
          <input
            v-model="loginWithPass.password"
            type="password"
            class="form-control"
          />
        </div>
      </div>

      <div v-if="loginwithphone">
        <h4 class="text-center">Login with phone number</h4>
        <div class="form-group">
          <div class="row">
            <div class="col-md-4">
              <label for="username">select Code</label>

              <select name="" v-model="countrycode" class="form-control" id="">
                <option
                  v-for="country in all_countries"
                  :key="country.id"
                  :value="country.callCode"
                  >{{ country.fullName }}</option
                >
              </select>
            </div>
            <div class="col-md-8">
              <label for="username">enter your number</label>
              <input type="text" v-model="phonenumber" class="form-control" />
            </div>
          </div>
          <span v-if="loginwithphone && Isverified"
            ><a
              @click.prevent="sendVerificationCode"
              href="#"
              class="text-danger"
              >Resend code</a
            >
            <input type="text" v-model="OTP" class="form-control" placeholder="Enter OTP">

            </span
          >
        </div>
      </div>

      <!-- <div v-if="loginwithphone && verify_code">
        <button
          @click.prevent="VerifyOTP"
          @keydown.enter="VerifyOTP"
          class="btn btn-primary btn-block mb-3"
        >
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else>Verify OTP</span>
        </button>
      </div> -->
      <div v-if="loginwithphone && Isverified">
        <button
          @click.prevent="Auth"
          @keydown.enter="Auth"
          class="btn btn-primary btn-block mb-3"
        >
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else>Login</span>
        </button>
      </div>
      <div v-if="login || register">
        <button
          @click.prevent="Auth"
          @keydown.enter="Auth"
          class="btn btn-primary btn-block mb-3"
        >
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else> {{ register == true ? "Register" : "Login" }}</span>
        </button>
      </div>

      <div v-if="loginwithphone && !Isverified">
        <button
          @click.prevent="sendVerificationCode"
          class="btn btn-primary btn-block mb-3"
        >
          Send code
        </button>
      </div>
      <span v-if="register == true" class="text-center">
        Are you Registered Already ?
        <a href="#" @click.prevent="NavigateLogin" class="text-danger"
          >Login</a
        ></span
      >
      <span v-if="login">
        <small v-if="login == true" class="pt-2">
          Don't Have an Account ?
          <a href="#" @click.prevent="NaviageteRegister" class="text-danger"
            >Register</a
          ></small
        >
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp;
        <small v-if="login == true" class="pt-2"
          ><a
            href="#"
            @click.prevent="LoginWIthPhone"
            @keydown.enter="LoginWIthPhone"
            class="text-danger"
            >Login With Phone</a
          ></small
        ></span
      >

      <small v-if="loginwithphone == true" class="pt-2">
        Don't Have an Account ?
        <a href="#" @click.prevent="NaviageteRegister" class="text-danger"
          >Register</a
        ></small
      >
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;
      <small v-if="loginwithphone == true" class="pt-2"
        ><a href="#" @click.prevent="LoginWIthEmail" class="text-danger"
          >Login With Email</a
        ></small
      >
      <!-- 
        <a href="#" class="modal__close">&times;</a> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      Isverified: false,
      OTP:"",
      verify_code: false,
      register: false,
      login: true,
      loginwithphone: false,
      showVerifyInput: false,
      sentcodeText: "Send Code",
      showVerifySuccessMsg: "",
      phonenumber: "",
      countrycode: "",
      guid: "",
      showPasswordCheck: false,
      showAPIError: false,
      errors: [],
      verification_code: "",
      showConfirmOTPInput: false,
      pack: "foodhub",
      profileIimage: null,
      fullphone:"",
      registerData: {
        firstname: "",
        lastname: "",
        email: "",
        countryId: "",
        password: "",
        phoneNumber: "",
        logo: "",
        url: null,
        confirm_password: ""
      },
      loading: false,
      loginWithPass: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("dashboard", ["all_countries"])
  },

  mounted() {
    this.$store.dispatch("dashboard/GetAllCountries");
  },

  methods: {
    handleImage(e) {
      this.registerData.logo = e.target.files[0];
      this.profileIimage = URL.createObjectURL(this.registerData.logo);
    },

    goBack() {
      this.$router.go(-1);
    },

    NavigateLogin() {
      this.register = false;
      this.loginwithphone = false;
      this.login = true;
    },
    NaviageteRegister() {
      this.login = false;
      this.loginwithphone = false;
      this.register = true;
    },

    LoginWIthPhone() {
      this.login = false;
      this.register = false;
      this.loginwithphone = true;
    },

    LoginWIthEmail() {
      this.loginwithphone = false;
      this.register = false;
      this.login = true;
    },
    verifyCode() {
      const payload = {
        "OTPReasonId": 40,
        "UserTypeId": 1,
        "PhoneNumber": this.fullphone,
        "Email": "",
        "CallCode": this.countrycode,
        "OTP": this.verification_code

      }
      this.$store
        .dispatch("default/verifyOTP", payload)
        .then((response) => {
          if (response.status == 200) {
            // console.log(response.data);
            localStorage.setItem("adminguid", response.data);
            this.showVerifyInput = false;
            this.showVerifySuccessMsg = true;
            this.guid = response.data;
            this.verify_code = false;
          }
        });
    },

    sendVerificationCode() {
      let user_phone = this.phonenumber;
      if (user_phone.charAt(0) == 0 || user_phone.charAt(0) == 1) {
        var userPhone = user_phone.substring(1);
      } else {
        userPhone = user_phone;
      }

      this.fullphone = `${this.countrycode}${userPhone}`;
      const data = {
        phoneNumber: this.fullphone,
        callCode: this.countrycode,

        //register payload
        PhoneNumber: this.fullphone,
        CallCode: this.countrycode,
        Email: "",
        OTP: "",
        OTPReasonId: 40,
        UserTypeId: 1,
      };

      if (this.register) {
        this.$store.dispatch("default/sendOTP", data).then((res) => {
          if (res.status == 200) {
            this.showVerifyInput = true;
            (this.sentcodeText = "Resend Code"), (this.verify_code = true);
          }
        });
      } else {
        const payload = {
       
        PhoneNumber: this.fullphone,
        CallCode: this.countrycode,
        Email: "",
        OTP: "",
        OTPReasonId: 20,
        UserTypeId: 1,
      };
        this.$store.dispatch("default/sendOTP", payload).then((res) => {
          console.log(res)
          if (res.status == 200) {
            this.guid = res.data;
            this.showVerifyInput = true;
            (this.sentcodeText = "Resend Code"), (this.Isverified = true);
          }
        });
      }
    },
    VerifyOTP(){},
    Auth() {
      if (this.register) {
        if (this.registerData.password !== this.registerData.confirm_password) {
          this.showPasswordCheck = true;
          return false;
        }
        let user_phone = this.phonenumber;
        if (user_phone.charAt(0) == 0 || user_phone.charAt(0) == 1) {
          var userPhone = user_phone.substring(1);
        } else {
          userPhone = user_phone;
        }
        let usernum = `${this.countrycode}${userPhone}`;

        const formData = new FormData();
        formData.append("firstname", this.registerData.firstname);
        formData.append("lastname", this.registerData.lastname);
        formData.append("countryId", this.countrycode);
        formData.append("password", this.registerData.password);
        formData.append("phoneNumber", usernum);
        formData.append("Email", this.registerData.email);
        formData.append("logo", this.registerData.logo);

        const payload = {
          data: formData,
          phoneNumberGUID: this.guid
        };
        this.loading = true;
        this.$store
          .dispatch("foodhub/CreateAccount", payload)
          .then((res) => {
            if (res.status == 200) {
              this.loading = false;
              this.$swal({
                icon: "success",
                title: "success",
                text: "You have successfully register.Login now."
              });

              this.loginwithphone = false;
              this.register = false;
              this.login = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.showAPIError = true;
            this.errors = err.response.data;
          });
      } else if (this.login) {
        const payload = {
          email: this.loginWithPass.email,
          password: this.loginWithPass.password
        };
        this.loading = true;
        this.$store
          .dispatch("foodhub/LoginWithPass", payload)
          .then((res) => {
            this.loading = false;
            localStorage.setItem("login_status", true);
            this.$store.dispatch("default/getAccountInfo", res.data);
            localStorage.setItem("packageCountryId", res.data.countryId);
            localStorage.setItem("clientCountryISO", res.data.countryISOCode);
            localStorage.setItem("clientguid", res.data.guid);
            localStorage.setItem("BeneficiaryCountryId", res.data.countryId);
            location.reload();
            const modal = document.getElementsByClassName("modal");
            modal[0].style.visibility = "hidden";
            modal[0].style.opacity = 0;
          })
          .catch((err) => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      } else if (this.loginwithphone) {
        const payload = {
          OTPReasonId: 20,
          UserTypeId: 1,
          PhoneNumber: this.fullphone,
          Email: "",
          CallCode: this.countrycode,
          OTP: this.OTP,
          guid: this.guid
        };
        this.loading = true;
        this.$store
          .dispatch("foodhub/LoginWithPhone", payload)
          .then((res) => {
            this.loading = false;
            localStorage.setItem("login_status", true);
            this.$store.dispatch("default/getAccountInfo", res.data);
            localStorage.setItem("packageCountryId", res.data.countryId);
            localStorage.setItem("clientCountryISO", res.data.countryISOCode);
            localStorage.setItem("clientguid", res.data.guid);
            localStorage.setItem("BeneficiaryCountryId", res.data.countryId);
            this.islogin = true;
            location.reload();
            const modal = document.getElementsByClassName("modal");
            modal[0].style.visibility = "hidden";
            modal[0].style.opacity = 0;
          })
          .catch((err) => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.modal {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(5, 5, 5, 0.836);
  transition: all 0.4s;
}

.modal:target {
  visibility: visible;
  opacity: 1;
}

.modal__content {
  border-radius: 4px;
  position: relative;
  width: 500px;
  max-width: 90%;
  background: #fff;
  padding: 1em 2em;
  /* top: -300px; */
}

.modal__footer {
  text-align: right;
}
a {
  color: #585858;
}

i {
  color: #d02d2c;
}
.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #585858;
  text-decoration: none;
}
</style>
