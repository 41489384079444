<template>
  <div>
    <div class="form-group">
      <button
        type="button"
        @click="getCurrentLocation"
        class="btn btn-outline-warning"
      >
        Use Current GPS: Location
      </button>
    </div>

    <div class="form-group">
      <label for="select country">Select Country</label>
      <select
      
        @change="handleChange()"
        v-model="clientCountry"
        class="form-control"
        name="country"
        id=""
      >
        <option
          v-for="country in all_countries"
          :key="country.id"
          :selected="country.id == countryId"
          :value="country"
          >{{ country.fullName }}</option
        >
      </select>
    </div>

    <div class="form-group">
      <label for="Busness Email">Search approximate package location</label>
      <input
        ref="autocomplete"
        type="text"
        :id="id"
        class="form-control autocompleteValue"
        placeholder="Search approximate package location"
      />
    </div>

    <div class="form-group">
      <label for="street">Street address</label>
      <input
        type="text"
        v-model="address"
        placeholder="Street address"
        class="form-control"
        disabled
      />
    </div>

    <a @click="searchMerchant" class="btn btn-primary btn-block"
      >Search Package</a
    >
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
/* eslint-disable */
const ADDRESS_COMPONENTS = {
  subpremise: "short_name",
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "long_name",
  country: "long_name",
  postal_code: "short_name"
};

export default {
  data() {
    return {
      autocompleteText: "",
      country: "",
      address: "",
      autocompleteValue:"",
      id: "this-component",
      countryId:localStorage.getItem("countryId") || null,
      country: "gh",
      autocomplete: null,
      clientCountry: {},
      options: {
        componentRestrictions: {
          country: "GH" // Replace with your desired country code (e.g., "US" for United States)
        }
      }
    };
  },
  computed: {
    ...mapGetters("dashboard", ["all_countries"])
  },

  watch: {
    autocompleteText: function(newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
    country: function(newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      });
    },
    address(newValue) {
      localStorage.setItem("address", newValue);
    },
    autocomplete(newValue) {
      console.log(document.querySelector(".autocompleteValue").value)
      
    },
  },
  mounted() {
    this.address = localStorage.getItem("address") || "";
    document.querySelector(".autocompleteValue").value = localStorage.getItem("autocomplete") || null;
    this.clientCountry.id = localStorage.getItem("countryId") || {}

    const options = this.options;

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    );

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.$store.dispatch("dashboard/GetAllCountries");

    localStorage.setItem("packageSubCategoryId", this.$route.params.id);
  },

  methods: {
    handleChange(e) {
      this.country = this.clientCountry.isO2Code.toLowerCase().toLowerCase();
      // this.$store.dispatch("healthhub/CountryId", this.clientCountry.id);

      localStorage.setItem("countryId", this.clientCountry.id);
    },
    searchMerchant() {
      if (!this.address || !this.autocomplete) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Package location can not be empty"
        });
        return false
      }
      const serviceType = localStorage.getItem("service");
      if (serviceType == "foodhub") {
        location.href = "/merchants-products";
      } else {
        location.href = "/healthhub-merchants";
      }
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      if (!place.geometry) {
        this.$emit("no-results-found", place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        this.processFormattedResults(this.formatResult(place));
      }
    },

    formatResult(place) {
      let returnData = {};

      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          let val =
            place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();

      // this.$store.dispatch(
      //   "healthhub/Setlat",
      //   (returnData["latitude"] = place.geometry.location.lat())
      // );
      // this.$store.dispatch(
      //   "healthhub/Setlong",
      //   (returnData["longitude"] = place.geometry.location.lng())
      // );

      localStorage.setItem(
        "latitude",
        (returnData["latitude"] = place.geometry.location.lat())
      );

      localStorage.setItem(
        "longitude",
        (returnData["longitude"] = place.geometry.location.lng())
      );

      return returnData;
    },

    processFormattedResults(formattedRes) {
      this.getAddressFrom(formattedRes.latitude, formattedRes.longitude);
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.getAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
            console.log(position.coords.latitude, position.coords.longitude)
            
            localStorage.setItem(
              "latitude", position.coords.latitude
            );

            localStorage.setItem(
              "longitude", position.coords.longitude
            );
          },
          (error) => {
            this.$toasted.error(
              "Locator is unable to find your address. Please search form the search box",
              {
                duration: 5000
              }
            );
          }
        );
      } else {
        this.$toasted.error("Your browser doesnt support geolocation API", {
          duration: 5000
        });
      }
    },

    getAddressFrom(lat, long) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyABmW5XJTsyoq8WrCQlXzZbyrHgyiJziXo`
        )
        .then((response) => {
          if (response.data.error_message) {
            this.$toasted.success(response.data.error_message, {
              duration: 5000
            });
          } else {
            localStorage.setItem("countryId", this.clientCountry.id);
            this.autocompleteValue = response.data.results[4].formatted_address
            localStorage.setItem("autocomplete",document.querySelector(".autocompleteValue").value);
            this.address = response.data.results
              ? response.data.results[0].formatted_address
              : response.data.results[2].formatted_address;
            this.addressData.city = response.data.results.slice(
              -2
            )[0].address_components[0].long_name;
            this.$store.dispatch("healthhub/StreetAddress", this.address);
            this.$store.dispatch("healthhub/setCity", this.addressData.city);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
input:disabled {
  background-color: #e6e3e1;
}
</style>
